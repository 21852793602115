import * as React from "react";
const Open = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 64 47"
    viewBox="0 0 64 47"
    width={256}
    height={256}
    {...props}
  >
    <path
      d="M59,19h-6.667L33.981,5.236C34.601,4.686,35,3.892,35,3c0-1.654-1.346-3-3-3s-3,1.346-3,3c0,0.892,0.399,1.686,1.019,2.236  L11.667,19H5c-2.757,0-5,2.243-5,5v18c0,2.757,2.243,5,5,5h54c2.757,0,5-2.243,5-5V24C64,21.243,61.757,19,59,19z M32,2  c0.551,0,1,0.449,1,1s-0.449,1-1,1s-1-0.449-1-1S31.449,2,32,2z M32,6.25L49,19H15L32,6.25z M62,42c0,1.654-1.346,3-3,3H5  c-1.654,0-3-1.346-3-3V24c0-1.654,1.346-3,3-3h54c1.654,0,3,1.346,3,3V42z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="M34 27v12c0 .552.448 1 1 1h6v-2h-5v-4h5v-2h-5v-4h5v-2h-6C34.448 26 34 26.448 34 27zM49 34.764l-4.105-8.211c-.207-.415-.669-.632-1.125-.526C43.319 26.133 43 26.536 43 27v13h2v-8.764l4.105 8.211C49.278 39.792 49.627 40 50 40c.076 0 .153-.009.23-.027C50.681 39.867 51 39.464 51 39V26h-2V34.764zM18 26c-2.206 0-4 1.794-4 4v6c0 2.206 1.794 4 4 4s4-1.794 4-4v-6C22 27.794 20.206 26 18 26zM20 36c0 1.103-.897 2-2 2s-2-.897-2-2v-6c0-1.103.897-2 2-2s2 .897 2 2V36zM28 26h-3c-.552 0-1 .448-1 1v13h2v-6h2c2.206 0 4-1.794 4-4S30.206 26 28 26zM28 32h-2v-4h2c1.103 0 2 .897 2 2S29.103 32 28 32z"
      fill="#000000"
      className="color000 svgShape"
    />
  </svg>
);
export default Open;
