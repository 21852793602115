import * as React from "react";
const SuperiorQuality = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 68 68"
    viewBox="0 0 68 68"
    width={256}
    height={256}
    {...props}
  >
    <path
      fill="#010101"
      d="M23.8,34.6c-0.1,0.4,0.1,0.8,0.4,1c0.3,0.2,0.7,0.3,1.1,0.1l8.7-4.6l8.7,4.6c0.3,0.2,0.7,0.2,1.1-0.1 c0.3-0.2,0.5-0.6,0.4-1l-1.7-9.8l7.1-6.9c0.3-0.3,0.4-0.7,0.3-1c-0.1-0.4-0.4-0.6-0.8-0.7l-9.8-1.4l-4.4-8.9 c-0.2-0.3-0.5-0.6-0.9-0.6s-0.7,0.2-0.9,0.6l-4.4,8.9L19,16.2c-0.4,0.1-0.7,0.3-0.8,0.7c-0.1,0.4,0,0.8,0.3,1l7.1,6.9L23.8,34.6z  M21.2,17.9l8.3-1.2c0.3,0,0.6-0.3,0.8-0.5L34,8.7l3.7,7.5c0.1,0.3,0.4,0.5,0.8,0.5l8.3,1.2l-6,5.9c-0.2,0.2-0.3,0.6-0.3,0.9 l1.4,8.3L34.5,29c-0.3-0.2-0.6-0.2-0.9,0l-7.4,3.9l1.4-8.3c0.1-0.3-0.1-0.7-0.3-0.9L21.2,17.9z"
      className="color010101 svgShape"
    />
    <path
      fill="#010101"
      d="M34 42c11.3 0 20.5-9.2 20.5-20.5S45.3 1 34 1c-11.3 0-20.5 9.2-20.5 20.5S22.7 42 34 42zM34 3c10.2 0 18.5 8.3 18.5 18.5S44.2 40 34 40s-18.5-8.3-18.5-18.5S23.8 3 34 3zM58.5 58.5l8.2-6.7c.3-.3.4-.7.3-1.1C66.8 50.2 66.4 50 66 50H51.4l1.6-3.2c.2-.3.1-.7 0-1-.2-.3-.5-.5-.8-.5H16c-.3 0-.7.2-.8.5-.2.3-.2.7 0 1l1.6 3.2H2c-.4 0-.8.3-.9.7-.1.4 0 .8.3 1.1l8.2 6.7-8.2 6.7c-.3.3-.4.7-.3 1.1C1.2 66.7 1.6 67 2 67h23.9c.3 0 .7-.2.8-.5.2-.3.2-.7 0-1l-1.6-3.2h17.5l-1.6 3.2c-.2.3-.1.7 0 1 .2.3.5.5.8.5H66c.4 0 .8-.3.9-.7.1-.4 0-.8-.3-1.1L58.5 58.5zM50.4 47.4c-.1.2-1 2.1-6.4 13H24c-6.1-12.3-4.3-8.7-6.4-13H50.4zM4.8 65l7-5.7c.2-.2.4-.5.4-.8 0-.3-.1-.6-.4-.8l-7-5.7h12.8l6.7 13H4.8zM43.7 65l6.6-13h12.8l-7 5.7c-.2.2-.4.5-.4.8 0 .3.1.6.4.8l7 5.7H43.7z"
      className="color010101 svgShape"
    />
    <path
      fill="#010101"
      d="M31.5,26.2c0.2,0.2,0.4,0.3,0.7,0.3c0,0,0,0,0,0c0.3,0,0.5-0.1,0.7-0.3l6.7-6.3c0.4-0.4,0.4-1,0-1.4 c-0.4-0.4-1-0.4-1.4,0l-5.9,5.6L30,21.4c-0.4-0.4-1-0.5-1.4-0.1c-0.4,0.4-0.5,1-0.1,1.4L31.5,26.2z"
      className="color010101 svgShape"
    />
  </svg>
);
export default SuperiorQuality;
