import * as React from "react";
const EasyReturn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={256}
    height={256}
    {...props}
  >
    <path
      d="m62.999,13.452c0-.139-.038-.268-.092-.39-.014-.032-.03-.06-.047-.09-.061-.107-.138-.202-.235-.28-.009-.007-.012-.018-.021-.025-.002-.002-.006-.002-.008-.003-.087-.064-.185-.115-.292-.148l-27.009-8.244c-.168-.05-.349-.055-.52-.014l-10.139,2.483s-.003.002-.005.002c-.002,0-.004,0-.005,0l-11.738,3.023s-.004.002-.007.003c-.003,0-.005,0-.008,0L1.817,12.797c-.13.026-.245.086-.352.158-.013.009-.029.009-.042.018-.014.011-.02.029-.033.04-.099.083-.187.179-.247.296-.003.005-.007.008-.009.013-.046.092-.078.191-.093.298-.004.029.005.055.003.084-.001.019-.013.035-.013.054l-.03,35.951c0,.433.283.815.698.94l29.947,9.049c.113.043.23.072.35.072.095,0,.19-.014.283-.042l30.023-9.039c.415-.125.699-.507.699-.94V13.455s0-.002,0-.003Zm-20.129,6.816l9.049-2.742v12.717l-9.049,3.355v-13.33Zm-7.89-14.04l23.656,7.222-2.025.611-3.623,1.093-16.134-4.868c-.023-.007-.046-.012-.07-.018l-8.192-2.477,6.389-1.565Zm-10.131,2.484l11.426,3.454c.023.007.046.013.071.018l13.241,3.995-7.774,2.351-25.075-7.729,8.111-2.089Zm-11.741,3.028l25.35,7.811-4.87,1.473-1.583.479-16.69-4.879-9.697-2.835,7.49-2.05Zm-10.116,3.328l11.317,3.308,16.705,4.883v34.198L2.964,48.982l.028-33.914Zm29.995,42.397V23.258l4.33-1.31,3.591-1.086v14.147c0,.321.157.622.421.806.076.052.159.09.244.119.028.01.056.014.085.021.06.015.12.024.182.028.017,0,.033.008.05.008.013,0,.026-.006.039-.007.102-.004.204-.018.302-.054l11.012-4.083c.385-.142.64-.51.64-.92v-13.989l7.155-2.16v34.244l-28.051,8.445Z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="m25.362,34.653l-6.498-2.81,3.2-1.331c.5-.208.738-.783.529-1.283-.207-.5-.783-.736-1.282-.529l-5.364,2.231c-.488.203-.727.756-.542,1.25l2.037,5.441c.148.394.521.638.919.638.114,0,.231-.02.344-.063.508-.19.765-.756.575-1.263l-1.239-3.308,6.543,2.829c.969.419,1.718,1.19,2.107,2.172.389.982.373,2.056-.047,3.026-.419.969-1.19,1.717-2.172,2.106-.982.388-2.057.372-3.027-.046l-12.966-5.606c-.497-.216-1.075.015-1.29.511-.215.497.014,1.075.512,1.291l12.966,5.606c.753.326,1.55.489,2.347.489.737,0,1.475-.139,2.181-.419,1.469-.582,2.624-1.702,3.251-3.152.627-1.451.652-3.059.07-4.528-.583-1.469-1.702-2.624-3.153-3.251Z"
      fill="#000000"
      className="color000 svgShape"
    />
  </svg>
);
export default EasyReturn;
